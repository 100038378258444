import './polyfills';
import App from './api/app';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';

import('./imports/react');

window.$ = window.jQuery = $;
import('./imports/jquery_plugins').then(() => {
    import('./imports/bootstrap');
});
import('./imports/highcharts');
import('./imports/anychart');
import("./imports/devextreme");
import("./imports/tinymce");
import("./imports/codemirror");
import("./imports/outros");

let carregarScriptJS = (script) => {
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.src = script + '?v=' + App.version;
    $("head").append(s);
}

$(window).on('load', function() {
    new window.App();
    carregarScriptJS('assets/js/sys/libs/jquery.ba-throttle-debounce.min.js');
    carregarScriptJS('assets/js/sys/libs/context_menu.js');
    carregarScriptJS('assets/js/sys/libs/select-multi-column.js');
    carregarScriptJS('assets/js/sys/selectpicker_bp.js');
    carregarScriptJS('assets/js/sys/table_bp_padrao.js');
    carregarScriptJS('assets/js/sys/fix.js');
});

import('./views/public.js');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line no-unused-vars
const WS_URI = window.WS_URI = process.env.NODE_ENV !== "production" ? ('http://' + window.location.hostname + ":"+ (process.env.REACT_APP_WS_PORT || 3001) +"/webservice/index.php/") : "./webservice/index.php/";
// eslint-disable-next-line no-unused-vars
const WS_ROOT = window.WS_ROOT = process.env.NODE_ENV !== "production" ? ('http://' + window.location.hostname + ":"+ (process.env.REACT_APP_WS_PORT || 3001) +"/" ): "./";
// eslint-disable-next-line no-unused-vars
const VIEW_URI = window.VIEW_URI = process.env.NODE_ENV !== "production" ? ('http://' + window.location.hostname + ":"+ (process.env.REACT_APP_WS_PORT || 3001) +"/webservice/especificos/view/") : "./webservice/especificos/view/";
// eslint-disable-next-line no-unused-vars
const WS_URI_PE_PHONEGAP = window.WS_URI_PE_PHONEGAP = "./";
// eslint-disable-next-line no-unused-vars
const LANGS_URI = window.LANGS_URI = "./langs/";
// eslint-disable-next-line no-unused-vars
const SYS_ADMIN = window.SYS_ADMIN = false;
// eslint-disable-next-line no-unused-vars
const AUTH_TOKEN = window.AUTH_TOKEN = false;
// eslint-disable-next-line no-unused-vars
const XHR_WITH_CREDENTIALS = window.XHR_WITH_CREDENTIALS = false;
// eslint-disable-next-line no-unused-vars
const LOGOUT = window.LOGOUT = false;
// eslint-disable-next-line no-unused-vars
const LOGIN_ANONIMO = window.LOGIN_ANONIMO = false;